// Entry point for the JavaScript bundling
import Rails from "@rails/ujs"

// Import Popper.js and Bootstrap
import '@popperjs/core'
import 'bootstrap'
import 'jquery'


import "@nathanvda/cocoon"

// Start Rails UJS (Unobtrusive JavaScript)
Rails.start()

// You can import your custom JavaScript modules here
// Example: import "./custom_module"

